.mainPolicy {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: left;
}

.container {
  max-width: 960px;
  padding: 5em 0;
  padding: 0 1em;
}

.mt {
  margin-top: 2em;
  margin-bottom: 1em;
}

.policyText {
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  line-height: 24px;
}

.privacyHeader {
  margin: 1em 0;
}

ul {
  padding-left: 1rem;
}

.headerTerms {
  font-weight: bold;
  font-style: italic;
  font-family: Arial;
  font-size: 16px;
  color: #000000;
  margin: 0.5em 0;
}

span {
  font-size: 14px;
}
