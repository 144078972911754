@import "../../utils/commonStyle/variables.scss";

.formContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 480px;
  height: 60%;
  overflow: hidden;

  @include mobile {
    border: none;
    padding: 0;
    width: 300px;
  }

  form {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid $border-color;
    border-radius: 6px;
    padding: 30px 40px;
    margin: 0 10px;
    width: 380px;
    height: 480px;
    transition: all 0.3s ease-out;

    &.hide {
      margin-left: -100%;
    }

    header {
      text-align: center;

      .loginHead {
        font-family: "Inter Bold";
        font-size: 33px;
        color: #000000;
      }

      .subHeading {
        font-size: 15px;
        color: $primary-text;
        margin-top: 8px;
      }
    }

    .sentMessage {
      font-size: 13px;
      text-align: center;
      color: #000000;
      margin-bottom: 20px;

      p {
        margin-bottom: 6px;
      }
    }

    .emailInputContainer {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      width: 100%;

      .emailTitle {
        color: $primary-text;
        margin-bottom: 8px;
        text-align: initial;
      }

      .emailInput {
        font-family: "Inter";
        font-size: 15px;
        padding: 8px 16px;
        background: #ffffff;
        color: #000000;
        border: 1px solid $border-color;
        border-radius: 4px;

        &::placeholder {
          font-size: 14px;
          color: $border-color;
        }
      }

      .emailInput:focus {
        outline: 1px solid $primary-color;
      }

      .errorMessage {
        margin-top: 4px;
        height: 20px;
        font-size: 12px;
        color: $error-color;
      }
    }

    .otpButton {
      padding: 8px;
      background: $primary-color;
      border-radius: 4px;
      border: none;
      color: #ffffff;
      font-size: 14px;
      font-family: "Inter";
      cursor: pointer;
    }
  }
}

.resendLink {
  font-family: "Inter Bold";
  font-size: 15px;
  text-align: center;
  color: $primary-color;
  cursor: pointer;

  &.inactive {
    color: #d9d9d9;
    cursor: not-allowed;
  }
}

.quesText {
  font-size: 14px;
  color: #232d42;
  text-align: center;

  a {
    cursor: pointer;
    color: $primary-color;
  }
}
