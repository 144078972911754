@import "../../../utils/commonStyle/variables.scss";

.podcastContainer {
  text-align: left;
  width: 100%;
  height: 100%;

  display: flex;
  .podcastSideBar {
    border-right: 2px solid $border-color;
  }
  .podcastContent {
    padding: 40px;
    width: 75%;
  }

  .magSideBar {
    // min-width: 35%;
    border-right: 2px solid $border-color;
  }

  .magazineContent {
    padding: 40px;
    // width: 65%;
  }

  h1 {
    align-items: left;
  }
}

.podcastCard {
  display: flex;

  width: 100%;
  border-bottom: 2px solid $border-color;
  .podcastCircle {
    margin: 1em;
    width: 60px;
    height: 60px;
    // line-height: 30px;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    background: #000;
  }

  .podcastDetails {
    width: 80%;
    text-align: left;
    padding: 5px;
  }

  .descText {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #4a4a4a;
  }

  .titleText {
    font-family: "Poppins";
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #6d6d6d;
    padding-top: 5px;
  }

  .secondaryTitleText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
  }
}

.widthBox {
  width: 90%;
}

.upDownPadding {
  padding: 1em;
  border-bottom: 2px solid $border-color;
}

.createNewBox {
  display: flex;
  background: $primary-background-color;
  color: $primary-color;
  height: 60px;
  align-items: center;
  justify-content: center;

  .createText {
    padding: 0px 20px;
  }
}

.h100 {
  height: 100%;
}

.headersText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 1em;
}

.contentText {
  display: flex;
  align-items: center;
}

.createHeaderext {
  text-align: left;
  margin-bottom: 1em;
}

.audioButton,
.audioButton:hover {
  background-color: $primary-background-color !important;
  color: $primary-color !important;
}

.titleBox {
  width: 100%;
  margin: 3em 0px;
}

.buttonRow {
  padding-top: 1em;
  display: flex;
  justify-content: end;
}

.podcastBtn {
  margin-left: 1em;
  background: $primary-color;
  color: white;
}

.podcastEditRow {
  display: flex;
  justify-content: space-between;
}

.podcastEditBtns {
  display: flex;
  height: fit-content;
  margin-top: 1em;
}

.editBtn {
  background: #d1e1ff;
  color: #1b53bd;
}

svg {
  margin: -3px 5px;
}

.bookTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
  padding-top: 5px;
}

.bookDesc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #4a4a4a;
}

.bookImage {
  padding: 5px;
  height: auto;
  width: 80px;
}

.yearBox {
  display: flex;
  padding: 1em 0.5em;
  border-bottom: 2px solid $border-color;
  justify-content: space-between;

  span {
    padding: 0;
  }
  .addYearBtn {
    width: 6em;
  }
  .podcastBtn {
    margin-right: 0;
    margin-left: 1em;
  }
  .updateBtn {
    text-align: center;
  }
  .addbtn {
    margin-right: 1em;
  }
  .editionBtn {
    // width: 8em;
    width: 50%;
  }
}

.messageText {
  display: flex;
}

.noData {
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userImage {
  margin: 5px;
  padding: 5px;
  height: auto;
  width: 70px;
  border-radius: 50%;
  background: transparent;
}

.eventCard {
  display: flex;

  width: 100%;
  border-bottom: 2px solid $border-color;
  .podcastCircle {
    margin: 1em;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    background: #000;
  }

  .podcastDetails {
    width: 80%;
    text-align: left;
    padding: 5px;
  }

  .descText {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
  }

  .titleText {
    font-family: "Poppins";
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #6d6d6d;
    font-size: 12px;
  }

  .secondaryTitleText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    padding-top: 5px;
    color: #000000;
  }
}

.activepodcast {
  background-color: $primary-background-color;
  .podcastCircle {
    background-color: $primary-color;
  }
}

.play {
  margin-right: 1em;
}

.mobileDeviceCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.articleDesign {
  width: 450px;
  height: 700px;
  border: 2px solid black;

  .articleImg {
    margin: 1em 1em;
  }

  .articleHead {
    margin: 0.5em;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 38px;

    color: #000000;
  }
  .articleSubHead {
    margin: 0 1em;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;

    color: #4a4a4a;
  }
  .articleDesc {
    margin: 0 1em;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: justify;

    color: #000000;
  }
}

.modalTitle {
  font-size: 2rem;
  font-weight: 700;
}

.loadMoreBtn {
  margin: 0 5em;
}

.select__user__content {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-style: oblique;
}