@import "../../utils/commonStyle/variables.scss";

.sidebarContainer {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  overflow: hidden;

  justify-content: start;
  align-items: flex-start;

  border-right: 2px solid $border-color;

  @include print {
    display: none;
  }

  a {
    padding: 0 3px;
    color: black;
    text-decoration: none;
  }

  &.sidebarContainer-expanded {
    width: 160px;
    transition: all 0.3s ease-out;

    .linkContainer {
      p {
        opacity: 1;
        visibility: visible;
        pointer-events: unset;
        transition: all 0.15s ease-in;
      }
    }
  }

  &.sidebarContainer-closed {
    width: 70px;
    transition: all 0.3s ease-in;

    .linkContainer {
      p {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: all 0.15s ease-in;
      }
      svg {
        width: 20px;
      }
    }
  }
}

.burgerMenu {
  margin-bottom: 118px;
  padding: 0 26px 0 19px;
  cursor: pointer;
}

.sidebarTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
}

.linkContainer {
  cursor: pointer;
  padding: 15px 19px;
  margin: 10px 0;
  width: 100%;

  a {
    display: flex;
    align-items: center;
  }

  &.active {
    background-color: $primary-background-color;

    svg {
      fill: $primary-color;
    }

    p,
    a {
      color: $primary-color;
    }
  }
}

span {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
}
