@import "../../../utils/commonStyle/variables.scss";

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  min-height: 42px;
  background-color: transparent;
  color: #000;
  border: 1px solid currentcolor;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;

  &.primary {
    background-color: $primary-color;
    color: #fff;
  }

  &.secondary {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
  }

  &:disabled {
    border: none;
    background-color: #e3e3e3 !important;
    color: #878787 !important;
    cursor: not-allowed;
  }

  svg {
    fill: currentcolor;
    pointer-events: none;
  }

  span {
    margin-left: 10px;
  }

  &.noText {
    min-width: 42px;
    padding: 0;
  }

  &.noIcon {
    span {
      margin-left: 0;
    }
  }
}
