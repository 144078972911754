@import "../../utils/commonStyle/variables.scss";

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Inter";
  background: #ffffff;
  padding: 10px 25px;
  height: 70px;
  z-index: 1;
  margin-bottom: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

  @include print {
    display: none;
  }

  .logoImage {
    width: auto;
    height: 100%;
    object-fit: contain;
  }

  .userDetails {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 1em;
    svg {
      margin-right: 20px;
      cursor: pointer;
    }
    .userPhoto {
      height: 45px;
      width: 45px;
      background-color: white;
      border-radius: 50%;
      margin-right: 19px;
    }
  }

  .logout {
    border-color: transparent;
    margin-left: 15px;
  }
  .dashBoardText {
    margin-left: 1vw;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
  }
}

.chatActive {
  background: $primary-color;
  border: none;
  border-radius: 50%;
  color: $primary-color;
}
