.container {
  min-height: 100%;
  overflow: auto;
  background-color: white;

  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Inter";
    height: 100vh;
    .logoImage {
      object-fit: contain;
      margin-bottom: 36px;
    }
    .h2 {
      font-weight: 600;
      font-size: 26px;
      line-height: 175%;
    }
    .p {
      font-weight: 400;
      font-size: 16px;
      line-height: 175%;
      text-align: center;
      margin-bottom: 15px;
    }
    .btn {
      margin-top: 15px;
      color: rgba(92, 183, 192, 1);
    }
    .marginBottom {
      margin-bottom: 15px;
    }
  }
}
