// variables
$primary-color: #f0635a;
$primary-text: #8a92a6;
$border-color: #d9d9d9;
$success-color: #00bd51;
$error-color: #ef545d;
$background-color: white;
$primary-background-color: #f8dedc;

// responsive breakpoints
$mobile-width: 320px;
$tablet-width-sm: 768px;
$tablet-width-md: 900px;
$tablet-width-lg: 1024px;
$desktop-width-sm: 1366px;
$desktop-width-md: 1600px;
$desktop-width-lg: 1920px;
$desktop-width-xl: 2600px;

@mixin mobile {
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width-sm}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width-sm}) and (max-width: #{$tablet-width-lg}) {
    @content;
  }
}

@mixin tabletPort {
  @media (min-width: #{$tablet-width-sm}) and (max-width: #{$tablet-width-md}) {
    @content;
  }
}

@mixin tabletLand {
  @media (min-width: #{$tablet-width-md}) and (max-width: #{$tablet-width-lg}) {
    @content;
  }
}

@mixin desktopSmall {
  @media (min-width: #{$tablet-width-lg}) and (max-width: #{$desktop-width-sm}) {
    @content;
  }
}
@mixin desktopMedium {
  @media (min-width: #{$desktop-width-sm}) and (max-width: #{$desktop-width-md}) {
    @content;
  }
}
@mixin desktopLarge {
  @media (min-width: #{$desktop-width-lg}) and (max-width: #{$desktop-width-xl}) {
    @content;
  }
}
@mixin print {
  @media print {
    @content;
  }
}
