* {
  box-sizing: border-box;
  font-family: sans-serif;
}

.dashboardContainer {

  .summaryText {
    text-align: left;
    padding-left: 30px;
    padding-top: 10px;
  }
}

.podcastContainer {
  padding: 1rem;
  text-align: left;

  .podcastItemContainer {
    padding: 1rem;

    .paper {
      padding: 1rem;
      border: 1px solid grey;
      outline: none;
      box-shadow: none;
      border-radius: 10px;

      .headingContainer {
        padding-top: 1rem;

        .heading {

        }

        .icon {
          color: red;
        }
      }

      .titleContainer {
        padding-top: 1rem;
        .title {
          font-weight: bold;;
        }
      }

      .responseContainer {
        padding-top: 0.5rem;
      }
    }
  }
}

.graphHeading {
  text-align: left;
  padding-left: 2rem;
  font-weight: bold;;
}



