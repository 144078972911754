@import "../../../utils/commonStyle/variables.scss";

.header {
  text-align: left;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80vh;
}

.main {
  padding: 10px;
  height: 80vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.main::-webkit-scrollbar {
  width: 0.5rem;
}

.main::-webkit-scrollbar-track {
  margin-bottom: 3em;
}

.main::-webkit-scrollbar-thumb {
  background: grey;
}

.form {
  height: 5vh;
  position: fixed;
  bottom: 0;
  background-color: rgb(24, 23, 23, 0);
  min-width: 62vw;
  display: flex;
  margin: 1.5em 0;
  font-size: 1.5rem;
}

.form button {
  width: 30%;
  background-color: $primary-color;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
}

.input {
  line-height: 1.5;
  width: 100%;
  font-size: 1.5rem;
  background: rgb(58, 58, 58, 0.2);
  color: black;
  outline: none;
  border: none;
  padding: 0 10px;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.p {
  max-width: 500px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  color: white;
  text-align: center;
}

.message {
  display: flex;
  align-items: center;
  padding: 0.5em 0;
}

.sent {
  flex-direction: row-reverse;
}

.sent .p {
  color: black;
  background: #e5eeff;
  align-self: flex-end;
}

.received .p {
  background: #ffebeb;
  color: black;
}

.p :last-child {
  margin-bottom: 20em;
}

.img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 5px;
}

.emptyDiv {
  padding-top: 3em;
}

.messagesArea {
  margin-top: auto;
}

.dateBox {
  text-align: center;
  border: 1px solid rgb(156, 148, 148);
  background: gainsboro;
  padding: 5px 10px;
}

.centerBox {
  align-items: center;
  text-align: center;
  margin: 0.5em 0;
}

.timeLeft {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 3.5em;
  font-size: 12;
  color: rgb(164, 152, 152);
}

.timeRight {
  display: flex;
  margin-left: 3.5em;
  font-size: 12;
  color: rgb(164, 152, 152);
}
