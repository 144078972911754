@import "./variables.scss";

// fonts
@font-face {
  font-family: "Inter";
  src: local("Inter Regular"),
    url("../../static/fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter Bold";
  src: local("Inter Bold"),
    url("../../static/fonts/Inter-SemiBold.ttf") format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

body {
  font-family: "Inter";
  // font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  @include print {
    height: auto;
  }
}

.hidden {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  pointer-events: none;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.h-100 {
  height: 100%;
}

.h-50 {
  height: 50%;
}
