@import "../../utils/commonStyle/variables.scss";

.wrapper {
  min-height: 100vh;
  overflow: auto;

  .loginContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Inter";
    min-height: 100vh;

    .logoImage {
      object-fit: contain;
      margin-bottom: 12px;
    }
  }
}
