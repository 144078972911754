.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .body {
    max-width: 550px;
    padding: 20px;
    text-align: center;

    img {
      width: 100%;
      object-fit: contain;
    }

    h2 {
      font-family: "Inter Bold";
      font-size: 26px;
      margin-bottom: 16px;
    }

    p {
      font-family: "Inter";
      line-height: 1.6;
    }

    .btn {
      margin: 40px auto 0;
    }
  }
}
