@import "../../utils/commonStyle/variables.scss";

.layoutRest {
  display: flex;
  width: 100%;
  height: calc(100vh - 100px);
  overflow: auto;
  flex: 1 0;

  .layoutSidebar {
    display: flex;
  }

  .layoutChildren {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding-top: 5px;
    background-color: $background-color;
    overflow: auto;

    @include print {
      padding: 0;
      background-color: #fff;
    }
  }
}
